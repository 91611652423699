


































































import {Component, Vue} from 'vue-property-decorator';
import UBlock from '@/ui-lib/block/Block.vue';
import {genre} from '@/store/modules/genre';
import {compilation} from '@/store/modules/compilation';
import {CompilationTrackCount} from '@/types';
import {CompilationService} from '@/api';

@Component({
  components: {
    UBlock,
  },
  metaInfo() {
    return {
      title: 'Подборки',
    };
  }
})
export default class extends Vue {
  private counts: CompilationTrackCount[] = [];
  private isLoadingCount = false;

  private get genres() {
    return genre.context(this.$store).state.list;
  }

  private get compilations() {
    return compilation.context(this.$store).state.list;
  }

  private genreCompilations(genreId: number) {
    return this.compilations.filter((c) => c.genreId === genreId);
  }

  private compilationCount(compilationId: number): number {
    return this.counts.find((c) => c.id === compilationId)?.count || 0;
  }

  private async loadCounts() {
    this.isLoadingCount = true;

    try {
      this.counts = await CompilationService.trackCount();
    } catch (e) {
      alert(e.message);
    }

    this.isLoadingCount = false;
  }

  private created() {
    this.loadCounts();
  }
}
